<template>
  <div class="video">
    <div class="banner">
      <img src="@/assets/video/banner.png" alt="" />
      <span>中飞科技视频演示</span>
    </div>
    <div class="content">
      <div class="center">
        <div class="content_left">
          <ul>
            <li>
              <img src="@/assets/video/line.png" alt="" />
            </li>
            <li
              v-for="item in videoClassList"
              :key="item.classificationId"
              @click="classChange(item)"
              class="content_left_data"
            >
              <img :src="item.icon" alt="" />
              <span
                :class="{
                  checkedClass: item.videoClassificationName === checkedClass,
                }"
                >{{ item.videoClassificationName }}</span
              >
            </li>
            <li>
              <img src="@/assets/video/line.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="content_right">
          <div class="row">
            <span class="title">搜索专区</span>
            <el-input
              class="search"
              v-model="listQuery.videoName"
              placeholder="请输入视频名称"
              @change="getVideoList"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="getVideoList"
              ></i>
            </el-input>
            <!-- <span
              :class="[{ checked: checkedType === '默认排序' }, 'nochecked']"
              @click="checkedType = '默认排序'"
              >默认排序</span
            >
            <span
              :class="[
                { checked: checkedType === '上传时间' },
                'nochecked',
                'sort',
              ]"
              @click="sort('上传时间')"
            >
              <span> 上传时间 </span>
              <span class="icon">
                <i class="el-icon-caret-top"></i>
                <i class="el-icon-caret-bottom"></i>
              </span>
            </span>
            <span
              :class="[
                { checked: checkedType === '访问量' },
                'nochecked',
                'sort',
              ]"
              @click="checkedType = '访问量'"
            >
              <span> 访问量 </span>
              <span class="icon">
                <i class="el-icon-caret-top"></i>
                <i class="el-icon-caret-bottom"></i>
              </span>
            </span> -->
          </div>
          <ul>
            <li v-for="item in videoList" :key="item.videoId">
              <div class="list" @click="videoPlay(item)">
                <img :src="item.image" alt="" />
                <span>{{ item.videoName }}</span>
                <div class="maskLayer">
                  <div>
                    <img src="@/assets/video/videoPlay.png" alt="" />
                    播放视频
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <el-pagination
            background
            style="text-align: center; margin-top: 15px"
            layout="prev, pager, next, jumper"
            :total="total"
            :page-size.sync="listQuery.pageSize"
            :current-page.sync="listQuery.pageNum"
            @size-change="getVideoList"
            @current-change="getVideoList"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="videoDialog" width="700px">
      <video ref="video" class="video" :src="videoUrl" controls></video>
    </el-dialog>
  </div>
</template>

<script>
import { videoClassification, videoList } from "@/api/web";
export default {
  name: "",
  data() {
    return {
      listQuery: {
        videoName: null, // 视频名称
        classificationId: null, // 产品类型id
        pageNum: 1,
        pageSize: 15,
      },
      videoClassList: [],
      videoList: [],
      total: null,
      checkedType: "默认排序",
      checkedClass: "",
      videoUrl: null,
      videoDialog: false,
    };
  },
  created() {
    this.getVideoClassification();
  },
  mounted() {
    console.log(this.$refs.video);
  },
  methods: {
    async getVideoClassification() {
      const { data } = await videoClassification();
      this.videoClassList = data;
      this.checkedClass = data[0].videoClassificationName;
      this.listQuery.classificationId = data[0].videoClassificationId;
      this.getVideoList();
    },
    async getVideoList() {
      const { data } = await videoList(this.listQuery);
      this.videoList = data.list;
      this.total = data.total;
    },
    // sort(type) {
    //   this.checkedType = type;
    // },
    classChange(item) {
      this.listQuery.classificationId = item.videoClassificationId;
      this.checkedClass = item.videoClassificationName;
      this.getVideoList();
    },
    videoPlay(item) {
      this.videoUrl = item.videoUrl;
      this.videoDialog = true;
      this.$nextTick(() => {
        console.log(this.$refs.video);
        this.$refs.video.controlsList = "nodownload";
      });
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  margin-bottom: -2px;
  img {
    display: block;
  }
  span {
    position: absolute;
    top: 231px;
    left: 235px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
  }
}
.content {
  background: url("../../assets/video/content_bg.png") no-repeat;
  background-size: cover;
  padding-top: 62px;
  .center {
    display: flex;
    justify-content: space-between;
    width: 1500px;
    margin: 0 auto;
    padding-bottom: 58px;
    .content_left {
      height: 844px;
      width: 235px;
      padding-left: 14px;
      padding-top: 90px;
      background: rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      ul {
        li {
          display: flex;
          align-items: center;
          margin-top: 30px;
          cursor: pointer;
          &:first-child {
            margin-top: 0;
          }

          span {
            margin-left: 10px;
            height: 16px;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            color: #ffffff;
            line-height: 13px;
            opacity: 0.4;
          }
        }
        .content_left_data {
          img {
            max-width: 30px;
            max-height: 26px;
          }
          span {
            line-height: 20px;
          }
        }
      }
      .checkedClass {
        opacity: 1;
      }
    }
    .content_right {
      position: relative;
      left: -10px;
      padding-left: 76px;
      padding-right: 15px;
      margin-top: -23px;
      margin-left: -57px;
      width: 1328px;
      height: 888px;
      background: url("../../assets/video/content_right.png") no-repeat;
      background-size: cover;
      box-sizing: border-box;
      .row {
        display: flex;
        align-items: center;
        margin-top: 47px;
        .title {
          margin-right: 32px;
          height: 17px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: #ffffff;
          line-height: 13px;
        }
        .nochecked {
          display: inline-block;
          margin-right: 11px;
          background: url("../../assets/video/nochecked.png") no-repeat;
          background-size: cover;
          width: 94px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          color: #ffffff;
          cursor: pointer;
        }
        .checked {
          background: url("../../assets/video/checked.png") no-repeat !important;
        }
        .sort {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            &:first-child {
              margin-right: 4px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column;
            i {
              font-size: 12px;
              &:first-child {
                margin-bottom: -5px;
              }
            }
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        li {
          width: 20%;
          margin-bottom: 35px;
          cursor: pointer;
          &:hover .list {
            transition: all 0.1s;
            border: 2px solid #03aeb5;
          }
          &:hover .list .maskLayer {
            display: block;
          }
          .list {
            position: relative;
            width: 226px;
            height: 178px;
            border-radius: 15px;
            overflow: hidden;
            box-sizing: border-box;
            img {
              width: 100%;
              object-fit: scale-down;
            }
            span {
              position: absolute;
              bottom: 0;
              left: 0;
              padding-left: 24px;
              width: 100%;
              height: 40px;
              line-height: 40px;
              background-color: rgba($color: #000000, $alpha: 0.7);
              font-size: 14px;
              font-family: Microsoft YaHei;
              color: #ffffff;
            }
            .maskLayer {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              display: none;
              div {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 16px;
                color: #fff;
                img {
                  display: block;
                  width: 40px;
                  height: 40px;
                  margin: 0 auto;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
::v-deep {
  .search {
    width: 1050px;
    height: 49px;
    margin-right: 18px;
    input {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      height: 100%;
      color: #fff;
      &::placeholder {
        color: rgba($color: #fff, $alpha: 0.3);
        font-family: MicrosoftYaHei;
        font-size: 16px;
      }
    }
  }
  .el-icon-search {
    margin-top: 4px;
    margin-right: 26px;
    font-size: 23px;
    color: rgba($color: #fff, $alpha: 0.3);
    cursor: pointer;
  }
  .el-dialog {
    border-radius: 15px;
    .el-dialog__header {
      padding-top: 20px;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .video {
      width: 100%;
    }
    // video::-webkit-media-controls {
    //   overflow: hidden !important;
    // }
    // video::-webkit-media-controls-enclosure {
    //   width: calc(100% + 32px);
    //   margin-left: auto;
    // }
  }
  .el-pagination {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    .el-pagination__jump {
      color: #fff;
    }
  }
}
</style>
